@font-face {
  font-family: 'Exodus-Sharpen';
  src: url('../fonts/Exodus-Sharpen.eot');
  src: url('../fonts/Exodus-Sharpen.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Exodus-Sharpen.woff2') format('woff2'),
  url('../fonts/Exodus-Sharpen.woff') format('woff'),
  url('../fonts/Exodus-Sharpen.ttf') format('truetype'),
  url('../fonts/Exodus-Sharpen.svg#Exodus-Sharpen') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ISOCPEUR';
  src: url('../fonts/ISOCPEUI.woff2') format('woff2'),
  url('../fonts/ISOCPEUI.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ISOCPEUR';
  src: url('../fonts/ISOCPEUR.woff2') format('woff2'),
  url('../fonts/ISOCPEUR.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}