$base-black: #131313;

body {
  font-family: ISOCPEUR, serif;
  color: $base-black;
  padding: 0;
  margin: 0;
  background-color: #efefef;
}

* {
  box-sizing: border-box;

  &:focus, &:hover {
    outline: none;
  }
}

.hidden {
  opacity: 0 !important;
  visibility: hidden !important;
}

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.first-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  background-color: #efefef;

  &__burger {
    display: none;
  }

  &__basket {
    position: absolute;
    right: 60px;
    top: 60px;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    height: 20px;
    cursor: pointer;

    & > img {
      height: 100%;
    }
  }

  &__logo {
    font-family: Exodus-Sharpen, serif;
    text-transform: uppercase;
    font-size: 36px;
    letter-spacing: 3px;
    text-decoration: none;
    margin-bottom: 7px;
    color: $base-black;

    &:visited {
      color: $base-black;
    }
  }

  &-content {
    padding-top: 173px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--sub {
      padding-bottom: 20px;
    }

  }

  &-menu {
    //max-width: 600px;
    padding-bottom: 31px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__list {
      display: flex;
      //max-width: calc(100% - 30px);
      width: 600px;
      //justify-content: center;
      justify-content: space-between;
      margin: 0;
      list-style: none;
      padding: 0;

      &-item {
        margin: 0;
        display: block;

        &.first {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      &-link {
        font-size: 14px;
        color: $base-black;
        text-decoration: none;

        &:hover {
          cursor: pointer;
          color: #ccc;
          //transition: 0.3s;
        }
      }

      &--sub {
        width: 580px;
        margin-top: 10px;
        margin-left: 0;
        margin-right: 0;

        .header-menu__list-item {
          margin: 0;
        }

        .header-menu__list-link {
          color: #ccc;

          &.active {
            color: $base-black;
          }

          &:hover {
            color: $base-black;
          }
        }
      }

    }

    &--mob {
      display: none;
    }
  }

  &__dense {
    background-color: #efefef;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    display: none;
  }

}

.back {

  &-mob {
    display: none;
  }

}

.products-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  width: calc(100% - 30px);
  margin: 0 auto;
}

.product-item {
  width: 25%;
  position: relative;
  padding-bottom: 13.75%;

  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    opacity: 0;
    color: $base-black;
    margin: 5%;
  }

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &:hover {
    cursor: pointer;

    .product-item__title {
      opacity: 1;
      transition: 0.3s;
    }

  }
}

.product-card {
  max-width: 800px;
  width: 100%;
  //padding: 0 15px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  &__name {
    display: block;
    text-decoration: underline;
    color: $base-black;
    font-size: 14px;
  }

  &__link {
    display: block;
    color: $base-black;
    font-size: 14px;
    margin: 14px 0;
    line-height: 20px;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      color: #ccc;
      transition: 0.3s;
    }

  }

  &__desc {
    display: block;
    color: $base-black;
    font-size: 14px;
    line-height: 20px;
  }

  &__select {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;

    select {
      flex: 0 auto;
      border: 1px solid transparent;
      border-radius: 0;
      background-color: transparent;
      font-size: 12px;
      color: $base-black;
      font-weight: 400;
      padding: 9px 12px;
      text-align: left;
    }

    option {
      font-family: ISOCPEUR, serif;
      font-size: 14px;
    }

  }

  &__input {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;

    input {
      flex: 0 auto;
      border: 1px solid transparent;
      border-radius: 0;
      background-color: transparent;
      font-size: 12px;
      color: $base-black;
      font-weight: 400;
      padding: 9px 15px;
      text-align: left;
    }

  }

  &__submit {
    background: none;
    border: none;
    margin-left: auto;
    display: block;
    font-size: 14px;
    padding: 8px 12px;
    font-family: ISOCPEUR, serif;
    color: $base-black;

    &:hover {
      cursor: pointer;
      color: #cccccc;
    }

  }

  &__back {
    font-size: 14px;
    color: $base-black;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      color: #ccc;
      transition: 0.3s;
    }

  }

  &__slider {

    .swiper-slide {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &-wrap {
      width: 75%;
      height: 330px;
    }

    &__nav {
      position: absolute;
      color: $base-black;
      font-size: 14px;
      font-weight: 600;
      top: calc(50% - 15px);
      border: none;
      background: none;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      cursor: pointer;
      transition: color 200ms ease-in-out;

      &-prev {
        left: calc(12% - 8px);
      }

      &-next {
        right: calc(12% - 8px);
      }

      &:hover {
        color: #cccccc;
      }

    }

  }

  &-info {
    //background-color: green;
    //flex-basis: 180px;
    width: 25%;
    padding-left: 20px;
  }

}

.product-gallery {
  height: 100vh;
  width: 100%;

  &-info {
    color: $base-black;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 20vh;
    z-index: 1;
    display: flex;

    &__socials {
      margin-bottom: 23px;
      margin-top: 0;
      list-style: none;
      padding: 0;
      display: flex;
      justify-content: center;


      li {
        margin: 0 3px;

        a {
          color: $base-black;
          text-decoration: none;

          &:hover {
            cursor: pointer;
            color: #ccc;
            //transition: 0.3s;
          }
        }
      }


    }

    &__short {
      margin-top: 25px;
      font-size: 14px;
      text-align: center;

      p {
        margin: 0;
        line-height: 20px;

        &:hover {
          cursor: pointer;
          color: #ccc;
          //transition: 0.3s;
        }
      }

      .title {
        margin-top: 0;
        margin-bottom: 20px;
        letter-spacing: 3px;
      }

    }

    &-col {
      width: calc(100% / 3);
      padding: 0 10px;

      &__back {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }

      &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
      }

    }

    &__back {
      text-decoration: none;
      color: $base-black;

      &:hover {
        cursor: pointer;
        color: #ccc;
        //transition: 0.3s;
      }
    }

    &__order {
      background: none;
      border: none;
      padding: 0;
      font-size: 14px;
      font-family: ISOCPEUR, serif;
      text-decoration: none;
      color: $base-black;

      &:hover {
        cursor: pointer;
        color: #ccc;
        //transition: 0.3s;
      }
    }

  }

  &__slider {

    .swiper-slide {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__nav {
      position: absolute;
      font-size: 14px;
      color: $base-black;
      font-weight: 600;
      top: calc(50% - 15px);
      border: none;
      background: none;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      cursor: pointer;
      transition: color 200ms ease-in-out;

      &-prev {
        left: calc(24% - 8px)
      }

      &-next {
        right: calc(24% - 8px);
      }

      &:hover {
        color: #cccccc;
      }

    }

  }

}

.page-desc {
  display: block;
  max-width: 400px;
  text-align: center;
  color: $base-black;
  font-size: 14px;
  width: calc(100% - 30px);
  margin: 260px auto 100px;

  &--liza {
    margin-top: 0;
    margin-bottom: 0;

    & > p {
      margin-top: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &--event {
    margin-top: 50px;
    text-align: left;
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  height: 100%;
  z-index: auto;
}

.signature {
  margin: 0 auto 70px auto;
  display: block;
  width: 165px;
}

.contacts {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__link {
    font-size: 16px;
    text-decoration: none;
    color: $base-black;
  }
}

.main {
  flex: 1 1;
  position: relative;

  &-static {
    margin-bottom: 0 !important;
  }

}

.main-slider {
  position: relative;

  .swiper-lazy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .swiper-slide, .swiper-lazy {
    background-position: center;
    background-size: cover;
    transition-timing-function: ease-in-out;
  }

  &__wrapper {
    margin-top: 0 !important;
    //height: calc(100vh - 350px)
    height: 100%;
    //height: 760px;
    position: relative;
    flex: 1 1;
  }

  &__nav {
    position: absolute;
    font-size: 16px;
    color: $base-black;
    font-weight: 600;
    top: calc(50% - 15px);
    border: none;
    background: none;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    transition: color 200ms ease-in-out;

    &:hover {
      color: #cccccc;
    }

    &-next {
      right: calc(50% - 970px / 2 + 10px);
    }

    &-prev {
      left: calc(50% - 970px / 2 + 10px);
    }
  }
}

.live-slider {
  position: relative;
  width: 100%;

  &__link {
    color: $base-black;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    position: absolute;
    bottom: -42px;
    left: 41px;
  }

  &-wrap {
    width: 590px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .swiper-slide {
    height: calc(100vh - 324px);
    margin-bottom: 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__nav {
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    color: $base-black;
    top: calc(50% - 50px);
    border: none;
    background: none;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    transition: color 200ms ease-in-out;

    &-next {
      right: -110px;
    }

    &-prev {
      left: -110px;
    }
  }
}

.live-event-slider {
  position: relative;
  width: 100%;

  &__link {
    color: $base-black;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
  }

  &-wrap {
    margin: 0 auto;
    position: relative;
  }

  .swiper-slide {
    height: 440px;
    width: 590px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__nav {
    position: absolute;
    font-size: 16px;
    color: $base-black;
    top: calc(50% - 50px);
    border: none;
    background: none;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;

    &-next {
      right: -90px;
    }

    &-prev {
      left: -90px;
    }
  }
}


.arrow__down {
  position: absolute;
  font-size: 16px;
  color: #fff;
  bottom: 65px;
  left: calc(50% - 15px);
  border: none;
  background: none;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}

.liza-img {
  height: 100%;
  //width: 100%;
  object-position: center;
  object-fit: cover;

  &-wrap {
    overflow: hidden;
    position: relative;
    height: 440px;
    //margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }

}

.swiper-slide {
  display: block;
}

.product {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 232px;
  text-align: center;
  font-size: 14px;
  color: #131313;
  z-index: 3;
  text-decoration: none;

  &__line {
    margin-top: 0;
    margin-bottom: 7px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &__title {
    margin-bottom: 0;
    margin-top: 0;
    text-decoration: none;
  }

  &__material {
    margin: 0;
    text-decoration: none;
  }
}

.slogan {
  text-align: center;
  font-size: 14px;
  color: #585858;
  //margin-top: 85px;
}

.socials {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 74px;
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  z-index: 1;

  li {
    margin: 0 3px;
  }

  a {
    font-size: 14px;
    color: $base-black;
    text-decoration: none;
  }
}

.products {
  //padding-top: 160px;
  background-color: #efefef;

  &-slider {
    position: relative;

    .container {
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      //z-index: 2;
    }

    .swiper-slide {
      //height: 1090px;
      min-height: 100vh;
      height: 100vh;
      //height: -webkit-fill-available;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition-timing-function: ease-in-out;
    }

    &__nav {
      position: absolute;
      font-size: 16px;
      color: $base-black;
      font-weight: 600;
      top: calc(50% - 15px);
      border: none;
      background: none;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      cursor: pointer;
      transition: color 200ms ease-in-out;

      &-next {
        right: calc(50% - 970px / 2 + 10px);
      }

      &-prev {
        left: calc(50% - 970px / 2 + 10px);
      }

      &:hover {
        color: #cccccc;
      }

    }
  }
}

.footer {
  //border-top: 1px solid #CCCCCC;
  position: fixed;
  //bottom: -130px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100%);
  left: 0;
  right: 0;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 65px;
  background-color: #efefef;
  transition: 0.3s;

  &-static {
    bottom: auto !important;
    position: relative;
    padding: 40px 0;
    opacity: 1 !important;
    visibility: visible !important;
    transform: translateY(0) !important;
  }

  &.show {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }

  &__copyright {
    text-align: center;
    font-size: 14px;
    color: #CCCCCC;
  }

  &__menu {
    list-style: none;
    padding-left: 0;
    margin: 0 0 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &-item {
      margin: 10px 30px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    &-link {
      text-decoration: none;
      font-size: 14px;
      color: #CCCCCC;

      &:hover {
        transition: 0.5s;
        color: $base-black
      }
    }
  }
}

.live-event__img {
  display: none;
}


@media (max-width: 991px) {

  .socials {
    bottom: 50px;
  }

  .footer__menu-item {
    margin: 10px 30px !important;
  }

  .product-gallery__slider__nav {

    &-prev {
      left: 12%;
    }

    &-next {
      right: 12%;
    }

  }

  .products-slider__nav, .main-slider__nav {
    &-next {
      right: calc(50% - 720px / 2 + 30px);
    }

    &-prev {
      left: calc(50% - 720px / 2 + 30px);
    }
  }
}

@media (max-width: 767px) {

  .product__line {
    margin-bottom: 14px;
  }

  .product__title {
    margin-bottom: 4px;
  }

  .arrow__down {
    bottom: 35px;
  }

  .product-card {
    //padding: 0;

    &-info {
      padding: 0 15px;
    }
  }


  .product-card__slider .swiper-slide {
    background-size: cover;
  }

  .product-card__slider-wrap {
    height: 440px;
    width: 100%;
  }

  .product-card__submit {
    margin: 0 auto 45px auto;
  }

  .product-card__back.back-mob {
    text-align: center;
    margin-bottom: 50px;
  }

  .product-card-info {
    width: 100%;
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-card {
    &__link {
      margin: 1px 0;
    }

    &__name {
      margin: 1px 0;
    }
  }

  .page-desc {
    //margin-top: 20px;
  }

  .products {
    padding-top: 0;
  }

  .products-slider__nav, .main-slider__nav {
    &-next {
      right: calc(50% - 540px / 2 + 30px);
    }

    &-prev {
      left: calc(50% - 540px / 2 + 30px);
    }
  }

  //.main-slider__wrapper {
  //  height: 530px;
  //}

  //.main-slider__nav-next {
  //  right: 15px;
  //}
  //
  //.main-slider__nav-prev {
  //  left: 15px;
  //}

  .events__line {
    display: none;
  }

  .mob-menu {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .live-event-slider-wrap {
    display: none;
  }

  .live-event__img {
    display: block;
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;

    &--main {
      margin-bottom: 0;
    }
  }

  .live-slider__nav {
    color: #fff;

    &-next {
      right: 20px;
    }

    &-prev {
      left: 20px;
    }

  }

  .header-menu--mob {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: -110%;
    left: 0;
    right: 0;
    height: 100vh;
    transition: 0.5s;
    background-color: #efefef;
    opacity: 0;
    visibility: hidden;
    z-index: 2;

    &.open {
      top: 0;
      opacity: 1;
      visibility: visible;
    }

    .header-menu__list {
      display: flex;
      flex-direction: column;
      //flex: 1 1;
      font-size: 16px;

      &-link {
        font-size: 16px;
      }

      &-item {
        text-align: center;
        margin: 5px 0 !important;
      }
    }

    .header {
      &-content {
        padding-top: 14px;
        padding-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__logo {
        margin: 0;
      }

      &__close {
        position: absolute;
        background: none;
        border: none;
        padding: 0;
        left: 25px;
        top: 25px;
        width: 20px;
        height: 20px;
        transition: 0.3s;

        &:before {
          content: '';
          width: 20px;
          height: 2px;
          background-color: $base-black;
          margin-bottom: 3px;
          position: absolute;
          top: 9px;
          left: 0;
          transform: rotate(45deg);
        }

        &:after {
          content: '';
          position: absolute;
          top: 6px;
          left: 0;
          width: 20px;
          height: 2px;
          margin-top: 3px;
          background-color: $base-black;
          transform: rotate(-45deg);
        }

        &:hover {
          cursor: pointer;
        }
      }

    }

  }

  .header {
    min-height: 230px;
  }

  .header__dense {
    display: block;
    height: 70px;
    transition: 0.3s;
    transform: translateY(-70px);

    &.open {
      transform: translateY(0);
    }

    .header {
      &__basket {
        top: 25px;
        right: 25px;
      }

      &-content {
        padding-top: 14px;
        padding-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__logo {
        margin: 0;
        line-height: 37px;
      }

      &__burger {
        position: absolute;
        background: none;
        border: none;
        padding: 0;
        left: 25px;
        top: 25px;
        width: 20px;
        height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: 0.3s;

        &:before {
          content: '';
          width: 20px;
          height: 2px;
          background-color: $base-black;
          margin-bottom: 3px;
        }

        &:after {
          content: '';
          width: 20px;
          height: 2px;
          margin-top: 3px;
          background-color: $base-black;
        }

        &:hover {
          cursor: pointer;
        }
      }

    }

  }

  .header__logo.hidden {
    display: none;
  }

  .header.sticky .header__logo.hidden {
    display: block;
  }

  .liza-img-wrap {
    height: 530px;
  }

  .product-item {
    width: 50%;
    padding-bottom: 27.5%;
    margin-bottom: 15px;

    &__title {
      opacity: 1;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 0;
    }

  }

  .back {
    display: none;

    &-mob {
      display: block;
    }
  }

  .header-menu__list-item.back-mob {
    margin-bottom: 15px !important;
  }

  .header-menu__list-item.hidden {
    display: none;
  }

  .main {
    //margin-bottom: 0 !important;
  }

  .footer {
    padding-top: 10px;
    padding-bottom: 35px;
    //padding-top: 30px;
    //bottom: auto !important;
    //position: relative;
    //padding: 40px 0;
    //opacity: 1 !important;
    //visibility: visible !important;
    //transform: translateY(0) !important;

    &__menu {
      margin-bottom: 15px;
    }
  }

  .products-slider .swiper-slide {
    min-height: auto;
    background-size: cover;
    //height: 760px;
    padding-bottom: 0;
  }

  .product {
    bottom: 100px;
  }

  //.slogan {
  //  bottom: 37px;
  //}

  .header-menu__list-link {
    letter-spacing: 0.5px;
  }

  .header {

    &__basket {
      top: 25px;
      right: 25px;
    }

    &__logo {
      margin-bottom: 8px;
    }

    &-content {
      padding-top: 63px;
      padding-bottom: 0;
    }

    &-menu {
      &__list {
        flex-direction: column;
        width: auto;
        margin-top: 0;

        &-item {
          text-align: center;
          margin: 0 10px !important;
          line-height: 1.1;

          //&:first-of-type, &:last-of-type {
          //  margin: 0 10px;
          //}
        }
      }
    }
  }

  //.header {
  //  position: fixed;
  //  z-index: 2;
  //  background-color: #efefef;
  //  top: 0;
  //
  //  &-menu {
  //    max-width: 100%;
  //  }
  //
  //  &__burger {
  //    position: absolute;
  //    background: none;
  //    border: none;
  //    padding: 0;
  //    left: 25px;
  //    top: 25px;
  //    width: 20px;
  //    height: 20px;
  //    display: flex;
  //    opacity: 0;
  //    visibility: hidden;
  //    flex-direction: column;
  //    justify-content: center;
  //    transition: 0.3s;
  //
  //    &:before {
  //      content: '';
  //      width: 20px;
  //      height: 2px;
  //      background-color: $base-black;
  //      margin-bottom: 3px;
  //    }
  //
  //    &:after {
  //      content: '';
  //      width: 20px;
  //      height: 2px;
  //      margin-top: 3px;
  //      background-color: $base-black;
  //    }
  //
  //    &:hover {
  //      cursor: pointer;
  //    }
  //  }
  //
  //  &__basket {
  //    top: 25px;
  //    right: 25px;
  //  }
  //
  //  &-content {
  //    transition: 0.3s;
  //  }
  //
  //  &.sticky {
  //
  //    top: 0;
  //    //height: 70px;
  //    .header {
  //      //&__basket {
  //      //  top: 25px;
  //      //  right: 25px;
  //      //}
  //
  //      &__burger {
  //        opacity: 1;
  //        visibility: visible;
  //      }
  //
  //      &-content {
  //        //height: 70px;
  //        padding-top: 14px;
  //        padding-bottom: 14px;
  //        //padding: 0;
  //        display: flex;
  //        align-items: center;
  //        justify-content: center;
  //      }
  //
  //      &__logo {
  //        margin: 0;
  //      }
  //
  //      &-menu {
  //        position: absolute;
  //        top: 70px;
  //        left: 0;
  //        right: 0;
  //        height: 0;
  //        opacity: 0;
  //        visibility: hidden;
  //      }
  //    }
  //
  //    &.open {
  //      .header-menu {
  //        transition: 0.3s;
  //        height: auto;
  //        opacity: 1;
  //        visibility: visible;
  //        top: 70px;
  //        background: #efefef;
  //        left: 0;
  //        right: 0;
  //        padding-top: 15px;
  //        box-shadow: 0 4px 5px 0 #00000017;
  //      }
  //    }
  //
  //  }
  //
  //  &__logo {
  //    margin-bottom: 15px;
  //  }
  //
  //  &-content {
  //    padding-top: 70px;
  //    padding-bottom: 0;
  //  }
  //
  //  &-menu {
  //    &__list {
  //      flex-direction: column;
  //
  //      &-item {
  //        text-align: center;
  //        margin: 5px 10px;
  //
  //        &:first-of-type, &:last-of-type {
  //          margin: 5px 10px;
  //        }
  //      }
  //    }
  //  }
  //}
}

@media (max-width: 575px) {

  .products-slider__nav, .product-card__slider__nav, .main-slider__nav {
    &-next {
      right: 27px;
    }

    &-prev {
      left: 27px;
    }
  }

  .footer__menu {
    flex-direction: column;

    &-item {
      margin: 0 10px !important;
      line-height: 16px;
    }
  }

  .product-item {
    width: 100%;
    padding-bottom: 57%;
  }
}


@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}

@keyframes colorRotate {
  from {
    color: #6666ff;
  }
  10% {
    color: #0099ff;
  }
  50% {
    color: #00ff00;
  }
  75% {
    color: #ff3399;
  }
  100% {
    color: #6666ff;
  }
}

.rainbow-animate {
  animation: colorRotate 5s linear 0s infinite;
}

.no-scroll {
  overflow-y: hidden !important;
}

.events {

  &__line {
    width: 100%;
    overflow-x: hidden;
  }

  &__list {
    display: flex;

    &-wrapper {
      cursor: grab;
      display: flex;
    }
  }

  &__item {
    //min-width: calc(100vw / 4);
    padding: 0 15px;
    box-sizing: border-box;
    flex-grow: 1;
    flex-shrink: 0;

    //@for $i from 1 through 10 {
    //  &:nth-child(#{$i}) {
    //    background-color: hsl(25 * $i, 50%, 70%);
    //  }
    //}
  }

  &__img {
    height: 440px;
    width: 590px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

}
